<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            Istituto Marangoni - это знаменитая частная итальянская школа моды и дизайна. Она расположена в Милане, в Ломбардии на севере Италии, и имеет филиалы во Флоренции, Лондоне и Париже, Шанхае и Шэньчжэне в Китае, Мумбаи в Индии и Майами в Соединенных Штатах.
            <br>
            Istituto Marangoni, основанный в 1935 году в Милане, представляет собой глобальную сеть высококлассных, престижных, мультикультурных школ, которые обучают более 4500 иностранных студентов в год и выпустили более 45000 профессионалов в сфере роскоши.
            <br>
            Знаменитые выпускники: Доменико Дольче (Dolce & Gabbana), Франко Москино (Moschino), Алессандра Факкинетти (Tod’s), Жюли де Либран (Sonia Rykiel), Родольфо Пальялунга (Jil Sander) и другие.
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Fashion & design
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="fd">Бакалавриат Fashion Design</option>
                                <option value="fdw">Магистратура Fashion Design Womenswear</option>
                                <option value="fscd">Бакалавриат Fashion Styling & Creative Direction</option>
                                <option value="mfscd">Магистратура Fashion Styling, Creative Direction</option>
                                <option value="fb">Бакалавриат Fashion Business</option>
                                <option value="fbm">Магистратура Fashion Buying and Merchandising</option>
                                <option value="flbm">Магистратура Fashion and Luxury Brand Management</option>
                                <option value="fpcd">Магистратура Fashion Promotion, Communication & Digital Media</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'fd'" id="fd">
                        <template #title>
                            Бакалавриат Fashion Design
                        </template>
                        <template #description>
                            Современные модельеры являются катализаторами творчества, формируют новые тенденции, реагируют на запросы потребителей, отраслевые разработки и новые технологии, от первоначального дизайна до готовой одежды. Этот курс, посвященный воспитанию оригинального стиля, готовит высококвалифицированных дизайнеров для увлекательной индустрии моды. Участники узнают, как создавать коллекции на основе точного анализа рынка, тенденций, материалов и тканей, а также воплощают идеи и вдохновение, почерпнутые из любой формы, концепции или искусства, или просто из собственного опыта, успешно сочетая личное видение с отраслевыми потребностями или стратегиями дизайна бренда.
                        </template>
                        <template #duree>
                            3 года 
                        </template>
                        <template #language>
                            английский.
                        </template>
                        <template #cost>
                            22.700 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            октябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'fdw'" id="fdw">
                        <template #title>
                            Магистратура Fashion Design Womenswear 
                        </template>
                        <template #description>
                            Программа последипломного образования Fashion Design Womenswear оснащает участников творческими навыками и профессионализмом, требуемыми глобальной системой моды. Курс имеет выдающиеся связи с промышленностью. Большое количество выпускников успешно работают в крупных модных и роскошных брендах или управляют собственными модными лейблами.
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            35.300 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'fscd'" id="fscd">
                        <template #title>
                            Бакалавриат Fashion Styling & Creative Direction
                        </template>
                        <template #description>
                            Обладая страстью к стилю, творческим мышлением и множеством визуальных идей, студенты учатся изобретать и определять образ одежды, продукта или бренда. Получив инструктаж высшего уровня, они готовы начать свою карьеру в качестве стилистов, которые вдохновляют. Свои таланты они используют в различных областях моды и творческих индустриях, включая создание статей, рекламных кампаний, имиджа бренда и предложений по рестайлингу. Их работа востребована в социальных сетях и визуальном мерчендайзинге, а также в музыкальных клипах, телешоу и фильмах.
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            22.500 евро 
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'mfscd'" id="mfscd">
                        <template #title>
                            Магистратура Fashion Styling, Creative Direction
                        </template>
                        <template #description>
                            Курс обучает участников, чтобы они стали экспертами в области визуального изображения, с расширенными навыками по таким предметам, как: знание стиля, статьи о моде как для офлайн, так и для онлайн-СМИ и социальных платформ
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            35.300 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь.
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'fb'" id="fb">
                        <template #title>
                            Бакалавриат Fashion Business
                        </template>
                        <template #description>
                            Профессионалы модного бизнеса руководят предпринимательским духом модной компании. Они работают в сфере производства, коммуникации, маркетинга и розничной торговли и должны хорошо разбираться в моде. Они могут с большой точностью определить, какие инструменты им необходимы для успешного позиционирования бренда, коллекции или даже отдельного продукта на рынках моды и предметов роскоши. Они эксперты в модном бизнесе. Этот очный курс представляет собой стратегическое и ориентированное на рынок сочетание основных перспектив модного бизнеса; охватывает стратегию, бюджетирование, финансы, разработку продуктов, маркетинг моды, коммуникации, брендинг, управление розничными операциями и глобальные каналы сбыта.
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            22.500 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'fbm'" id="fbm">
                        <template #title>
                            Магистратура Fashion Buying and Merchandising
                        </template>
                        <template #description>
                            Узнайте, как собрать успешную коллекцию - от дизайнерского шоу-рума до магазина; Fashion Buyer решает, что будет в продаже в следующем сезоне. Они планируют закупки сезонных товаров, определяют предстоящие тенденции и выбирают правильный продукт и сочетание брендов. Fashion Buyers являются основными движущими силами мировой индустрии розничной торговли модной одеждой, ключевой сферой и влиятельной ролью в любом успешном модном бизнесе.
                        </template>
                        <template #duree>
                            1.5 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            35.500 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'flbm'" id="flbm">
                        <template #title>
                            Магистратура Fashion and Luxury Brand Management
                        </template>
                        <template #description>
                            Роль бренд-менеджера в модной индустрии luxe состоит в том, чтобы чтобы соответствовать видению и стратегии люксового бренда: мотивировать, вызывать желание и укреплять доверие с помощью различных каналов мерчендайзинга, маркетинга и коммуникаций.
                        </template>
                        <template #duree>
                            1,5 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            35.300 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            октябрь, февраль
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'fpcd'" id="fpcd">
                        <template #title>
                            Магистратура Fashion Promotion, Communication & Digital Media
                        </template>
                        <template #description>
                            Продвигать то, что модно, и делать продукты желанными в цифровой среде - это работа эксперта по продвижению моды и коммуникациям. Специалисты по цифровой коммуникации в сфере моды определяют и реализуют рекламные кампании и организуют рекламные мероприятия через наиболее подходящие каналы связи, которые отражают и представляют имидж бренда. Они управляют презентациями, мероприятиями, редакционными планами для социальных сетей, показами мод, выставками, мероприятиями по связям с общественностью, готовят редакционные планы для социальных сетей и активно используют инфлюенсеров и контент-маркетинг.
                        </template>
                        <template #duree>
                            1,5 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            35.300 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            октябрь, февраль
                        </template>
                    </Program>
                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>